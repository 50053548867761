import { __assign } from 'tslib';
import { mergePositionRestrictions, coordinatesToPositionRestrictions } from '../service/utils.js';
import { isUndefined } from '../utils.js';
import { getAspectRatio, getSizeRestrictions, getPositionRestrictions } from '../service/helpers.js';
import { copyState } from './copyState.js';
import { approximateSize } from '../service/approximateSize.js';
import { moveCoordinatesAlgorithm } from '../algorithms/moveCoordinatesAlgorithm.js';

function setCoordinates(state, settings, transform, 
// If you set safe to `false`, the coordinates can leave the visible area
safe) {
    if (safe === void 0) { safe = true; }
    var aspectRatio = getAspectRatio(state, settings);
    var sizeRestrictions = getSizeRestrictions(state, settings);
    if (state.visibleArea && safe) {
        sizeRestrictions = __assign(__assign({}, sizeRestrictions), { minWidth: Math.min(state.visibleArea.width, sizeRestrictions.minWidth), minHeight: Math.min(state.visibleArea.height, sizeRestrictions.minHeight), maxWidth: Math.min(state.visibleArea.width, sizeRestrictions.maxWidth), maxHeight: Math.min(state.visibleArea.height, sizeRestrictions.maxHeight) });
    }
    var positionRestrictions = getPositionRestrictions(state, settings);
    if (state.visibleArea && safe) {
        positionRestrictions = mergePositionRestrictions(positionRestrictions, coordinatesToPositionRestrictions(state.visibleArea));
    }
    var move = function (prevCoordinates, newCoordinates) {
        return moveCoordinatesAlgorithm(prevCoordinates, {
            left: newCoordinates.left - (prevCoordinates.left || 0),
            top: newCoordinates.top - (prevCoordinates.top || 0),
        }, positionRestrictions);
    };
    var resize = function (prevCoordinates, newCoordinates) {
        var coordinates = __assign(__assign(__assign({}, prevCoordinates), approximateSize({
            width: newCoordinates.width,
            height: newCoordinates.height,
            sizeRestrictions: sizeRestrictions,
            aspectRatio: aspectRatio,
        })), { left: 0, top: 0 });
        return move(coordinates, {
            left: prevCoordinates.left || 0,
            top: prevCoordinates.top || 0,
        });
    };
    var coordinates = __assign({}, state.coordinates);
    var transforms = Array.isArray(transform) ? transform : [transform];
    transforms.forEach(function (transform) {
        var changes;
        if (typeof transform === 'function') {
            changes = transform(__assign(__assign({}, state), { coordinates: coordinates }), settings);
        }
        else {
            changes = transform;
        }
        if (!isUndefined(changes.width) || !isUndefined(changes.height)) {
            coordinates = resize(coordinates, __assign(__assign({}, coordinates), changes));
        }
        if (!isUndefined(changes.left) || !isUndefined(changes.top)) {
            coordinates = move(coordinates, __assign(__assign({}, coordinates), changes));
        }
    });
    return __assign(__assign({}, copyState(state)), { coordinates: coordinates });
}

export { setCoordinates };
