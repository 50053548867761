import { moveToPositionRestrictions } from './utils.js';
import { getSizeRestrictions, getPositionRestrictions } from './helpers.js';

function roundCoordinates(state, settings) {
    var coordinates = state.coordinates;
    var sizeRestrictions = getSizeRestrictions(state, settings);
    var positionRestrictions = getPositionRestrictions(state, settings);
    var roundCoordinates = {
        width: Math.round(coordinates.width),
        height: Math.round(coordinates.height),
        left: Math.round(coordinates.left),
        top: Math.round(coordinates.top),
    };
    if (roundCoordinates.width > sizeRestrictions.maxWidth) {
        roundCoordinates.width = Math.floor(coordinates.width);
    }
    else if (roundCoordinates.width < sizeRestrictions.minWidth) {
        roundCoordinates.width = Math.ceil(coordinates.width);
    }
    if (roundCoordinates.height > sizeRestrictions.maxHeight) {
        roundCoordinates.height = Math.floor(coordinates.height);
    }
    else if (roundCoordinates.height < sizeRestrictions.minHeight) {
        roundCoordinates.height = Math.ceil(coordinates.height);
    }
    return moveToPositionRestrictions(roundCoordinates, positionRestrictions);
}

export { roundCoordinates };
