import 'tslib';
import { applyScale, maxScale } from '../../../service/utils.js';
import { getAreaSizeRestrictions } from '../../../service/helpers.js';
import { copyState } from '../../../state/copyState.js';
import { fitVisibleArea } from '../../../service/fitVisibleArea.js';

function simplestAutoZoomAlgorithm(state, settings) {
    var result = copyState(state);
    var widthIntersections = Math.max(0, result.coordinates.width - result.visibleArea.width);
    var heightIntersections = Math.max(0, result.coordinates.height - result.visibleArea.height);
    var areaSizeRestrictions = getAreaSizeRestrictions(state, settings);
    if (widthIntersections > heightIntersections) {
        result.visibleArea = applyScale(result.visibleArea, Math.min(result.coordinates.width / result.visibleArea.width, maxScale(result.visibleArea, areaSizeRestrictions)));
    }
    else if (heightIntersections > widthIntersections) {
        result.visibleArea = applyScale(result.visibleArea, Math.min(result.coordinates.height / result.visibleArea.height, maxScale(result.visibleArea, areaSizeRestrictions)));
    }
    return fitVisibleArea(result, settings);
}
function simplestAutoZoom(state, settings, action) {
    if (action && action.name === 'setCoordinates') {
        return simplestAutoZoomAlgorithm(state, settings);
    }
    return state;
}

export { simplestAutoZoom, simplestAutoZoomAlgorithm };
