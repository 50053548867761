import 'tslib';
import { getTransformedImageSize } from '../service/helpers.js';

function defaultPosition(state) {
    var visibleArea = state.visibleArea, coordinates = state.coordinates;
    var area = visibleArea || getTransformedImageSize(state);
    return {
        left: (visibleArea ? visibleArea.left : 0) + area.width / 2 - coordinates.width / 2,
        top: (visibleArea ? visibleArea.top : 0) + area.height / 2 - coordinates.height / 2,
    };
}

export { defaultPosition };
