import 'tslib';
import { ratio } from '../service/utils.js';
import { getTransformedImageSize } from '../service/helpers.js';

function defaultAreaSizeRestrictions(state, settings) {
    var boundary = state.boundary;
    var imageRestriction = settings.imageRestriction;
    var imageSize = getTransformedImageSize(state);
    var restrictions = {
        minWidth: 0,
        minHeight: 0,
        maxWidth: Infinity,
        maxHeight: Infinity,
    };
    if (imageRestriction === 'fillArea') {
        restrictions.maxWidth = imageSize.width;
        restrictions.maxHeight = imageSize.height;
    }
    else if (imageRestriction === 'fitArea') {
        if (ratio(boundary) > ratio(imageSize)) {
            restrictions.maxHeight = imageSize.height;
        }
        else {
            restrictions.maxWidth = imageSize.width;
        }
    }
    return restrictions;
}

export { defaultAreaSizeRestrictions };
