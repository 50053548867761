import 'tslib';
import { applyScale, fitToSizeRestrictions, applyMove, diff, getCenter, moveToPositionRestrictions, mergePositionRestrictions, toLimits } from '../../../service/utils.js';
import { getAreaSizeRestrictions, getAreaPositionRestrictions } from '../../../service/helpers.js';
import { copyState } from '../../../state/copyState.js';
import { getFixedStencilSize } from '../../helpers.js';

function fixedStencilAutoZoomAlgorithm(state, settings) {
    var result = copyState(state);
    var stencil = getFixedStencilSize(state, settings);
    // First of all try to resize visible area as much as possible:
    result.visibleArea = applyScale(result.visibleArea, (result.coordinates.width * result.boundary.width) / (result.visibleArea.width * stencil.width));
    // Check that visible area doesn't break the area restrictions:
    var scale = fitToSizeRestrictions(result.visibleArea, getAreaSizeRestrictions(result, settings));
    if (scale !== 1) {
        result.visibleArea = applyScale(result.visibleArea, scale);
        result.coordinates = applyScale(result.coordinates, scale);
    }
    result.visibleArea = applyMove(result.visibleArea, diff(getCenter(result.coordinates), getCenter(result.visibleArea)));
    // Center stencil in visible area:
    result.visibleArea = moveToPositionRestrictions(result.visibleArea, getAreaPositionRestrictions(result, settings));
    result.coordinates = moveToPositionRestrictions(result.coordinates, mergePositionRestrictions(toLimits(result.visibleArea), getAreaPositionRestrictions(result, settings)));
    return result;
}
function fixedStencilAutoZoom(state, settings, action) {
    if (action && action.immediately) {
        return fixedStencilAutoZoomAlgorithm(state, settings);
    }
    return state;
}

export { fixedStencilAutoZoom, fixedStencilAutoZoomAlgorithm };
