import 'tslib';
import { ratio } from '../service/utils.js';
import { isFunction } from '../utils.js';
import { approximateSize } from '../service/approximateSize.js';

function getFixedStencilSize(state, settings) {
    var boundary = state.boundary;
    var size = isFunction(settings.stencilSize) ? settings.stencilSize(state, settings) : settings.stencilSize;
    if (size.width > boundary.width || size.height > boundary.height) {
        size = approximateSize({
            sizeRestrictions: {
                maxWidth: boundary.width,
                maxHeight: boundary.height,
                minWidth: 0,
                minHeight: 0,
            },
            width: size.width,
            height: size.height,
            aspectRatio: {
                minimum: ratio(size),
                maximum: ratio(size),
            },
        });
    }
    return size;
}

export { getFixedStencilSize };
