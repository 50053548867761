import { __assign } from 'tslib';
import { ratio } from '../service/utils.js';
import { isFunction } from '../utils.js';
import { mergeSizeRestrictions } from '../service/sizeRestrictions.js';
import { getAreaSizeRestrictions } from '../service/helpers.js';
import { defaultPositionRestrictions } from './defaultPositionRestrictions.js';
export { defaultPositionRestrictions } from './defaultPositionRestrictions.js';
import { defaultVisibleArea } from './defaultVisibleArea.js';
export { defaultVisibleArea } from './defaultVisibleArea.js';
import { fixedDefaultSize, defaultSize } from './defaultSize.js';
export { defaultSize, fixedDefaultSize } from './defaultSize.js';
import { getFixedStencilSize } from './helpers.js';
export { getFixedStencilSize } from './helpers.js';
import { pixelsRestrictions } from './defaultSizeRestrictions.js';
export { pixelsRestrictions, retrieveSizeRestrictions } from './defaultSizeRestrictions.js';
import { defaultPosition } from './defaultPosition.js';
export { defaultPosition } from './defaultPosition.js';
import { defaultAreaPositionRestrictions } from './defaultAreaPositionRestrictions.js';
export { defaultAreaPositionRestrictions } from './defaultAreaPositionRestrictions.js';
import { defaultAreaSizeRestrictions } from './defaultAreaSizeRestrictions.js';
export { defaultAreaSizeRestrictions } from './defaultAreaSizeRestrictions.js';
import { autoZoom } from './postProcess/autoZoom/index.js';
export { autoZoom } from './postProcess/autoZoom/index.js';
export { fillBoundary, fitBoundary } from './defaultBoundary.js';

function withDefaults(settings) {
    return __assign(__assign({}, settings), { sizeRestrictions: function (state, basicSettings) {
            var restrictions;
            if (settings.sizeRestrictions) {
                restrictions = isFunction(settings.sizeRestrictions)
                    ? settings.sizeRestrictions(state, basicSettings)
                    : settings.sizeRestrictions;
            }
            else {
                restrictions = pixelsRestrictions(state, basicSettings);
            }
            if (basicSettings.stencilSize && state.visibleArea) {
                var stencilSize = getFixedStencilSize(state, __assign(__assign({}, basicSettings), { stencilSize: basicSettings.stencilSize }));
                var areaRestrictions = getAreaSizeRestrictions(state, basicSettings);
                restrictions = mergeSizeRestrictions(restrictions, {
                    maxWidth: (areaRestrictions.maxWidth * stencilSize.width) / state.boundary.width,
                    maxHeight: (areaRestrictions.maxHeight * stencilSize.height) / state.boundary.height,
                });
            }
            return restrictions;
        }, areaPositionRestrictions: function (state, basicSettings) {
            if (settings.areaPositionRestrictions) {
                return isFunction(settings.areaPositionRestrictions)
                    ? settings.areaPositionRestrictions(state, basicSettings)
                    : settings.areaPositionRestrictions;
            }
            else {
                return defaultAreaPositionRestrictions(state, basicSettings);
            }
        }, areaSizeRestrictions: function (state, basicSettings) {
            if (settings.areaSizeRestrictions) {
                return isFunction(settings.areaSizeRestrictions)
                    ? settings.areaSizeRestrictions(state, basicSettings)
                    : settings.areaSizeRestrictions;
            }
            else {
                return defaultAreaSizeRestrictions(state, basicSettings);
            }
        }, positionRestrictions: function (state, basicSettings) {
            if (settings.positionRestrictions) {
                return isFunction(settings.positionRestrictions)
                    ? settings.positionRestrictions(state, basicSettings)
                    : settings.positionRestrictions;
            }
            else {
                return defaultPositionRestrictions(state, basicSettings);
            }
        }, defaultCoordinates: function (state, basicSettings) {
            if (settings.defaultCoordinates) {
                return isFunction(settings.defaultCoordinates)
                    ? settings.defaultCoordinates(state, basicSettings)
                    : settings.defaultCoordinates;
            }
            else {
                var defaultSizeAlgorithm = settings.defaultSize;
                if (!defaultSizeAlgorithm) {
                    if (settings.stencilSize) {
                        defaultSizeAlgorithm = fixedDefaultSize;
                    }
                    else {
                        defaultSizeAlgorithm = defaultSize;
                    }
                }
                var size = isFunction(defaultSizeAlgorithm)
                    ? defaultSizeAlgorithm(state, basicSettings)
                    : defaultSizeAlgorithm;
                var defaultPositionAlgorithm_1 = settings.defaultPosition || defaultPosition;
                return [
                    size,
                    function (state) { return (__assign({}, (isFunction(defaultPositionAlgorithm_1)
                        ? defaultPositionAlgorithm_1(state, basicSettings)
                        : defaultPositionAlgorithm_1))); },
                ];
            }
        }, defaultVisibleArea: function (state, basicSettings) {
            if (settings.defaultVisibleArea) {
                return isFunction(settings.defaultVisibleArea)
                    ? settings.defaultVisibleArea(state, basicSettings)
                    : settings.defaultVisibleArea;
            }
            else {
                return defaultVisibleArea(state, basicSettings);
            }
        }, aspectRatio: function (state, basicSettings) {
            var _a;
            var minimum = 0;
            var maximum = Infinity;
            if (settings.aspectRatio) {
                (_a = isFunction(settings.aspectRatio)
                    ? settings.aspectRatio(state, basicSettings)
                    : settings.aspectRatio, minimum = _a.minimum, maximum = _a.maximum);
            }
            if (settings.stencilSize) {
                var stencilSize = getFixedStencilSize(state, __assign(__assign({}, basicSettings), { stencilSize: settings.stencilSize }));
                minimum = maximum = ratio(stencilSize);
            }
            return {
                minimum: minimum,
                maximum: maximum,
            };
        }, adjustStencil: !settings.stencilSize && settings.adjustStencil });
}
function defaultSettings() {
    return withDefaults({});
}
var defaultPostprocess = autoZoom;

export { defaultPostprocess, defaultSettings, withDefaults };
