import 'tslib';
import { applyMove, moveToPositionRestrictions, mergePositionRestrictions, coordinatesToPositionRestrictions } from '../service/utils.js';
import { getPositionRestrictions } from '../service/helpers.js';
import { copyState } from './copyState.js';

function moveCoordinates(state, settings, directions) {
    var result = copyState(state);
    result.coordinates = applyMove(result.coordinates, directions);
    result.coordinates = moveToPositionRestrictions(result.coordinates, mergePositionRestrictions(coordinatesToPositionRestrictions(result.visibleArea), getPositionRestrictions(result, settings)));
    return result;
}

export { moveCoordinates };
