import { hybridStencilAutoZoom } from './hybridStencilAutoZoom.js';
import { simplestAutoZoom } from './simplestAutoZoom.js';
import { fixedStencilAutoZoom } from './fixedStencilAutoZoom.js';

function autoZoom(state, settings, action) {
    var algorithm;
    if (settings.stencilSize) {
        algorithm = fixedStencilAutoZoom;
    }
    else if (settings.autoZoom) {
        algorithm = hybridStencilAutoZoom;
    }
    else {
        algorithm = simplestAutoZoom;
    }
    return algorithm(state, settings, action);
}

export { autoZoom };
