import { isRoughlyEqual } from '../utils.js';

function isEqualStateFields(a, b) {
    if (a === null && b === null) {
        return true;
    }
    else if (a === null || b === null) {
        return false;
    }
    else {
        var aKeys = Object.keys(a);
        var bKeys = Object.keys(b);
        return (aKeys.length === bKeys.length && aKeys.every(function (key) { return a[key] === b[key] || isRoughlyEqual(a[key], b[key]); }));
    }
}
function isEqualStates(a, b) {
    if (a === null && b === null) {
        return true;
    }
    else if (a === null || b === null) {
        return false;
    }
    else {
        return (isEqualStateFields(a.coordinates, b.coordinates) &&
            isEqualStateFields(a.boundary, b.boundary) &&
            isEqualStateFields(a.imageSize, b.imageSize) &&
            isEqualStateFields(a.visibleArea, b.visibleArea) &&
            a.transforms.rotate === b.transforms.rotate &&
            a.transforms.flip.horizontal === b.transforms.flip.horizontal &&
            a.transforms.flip.vertical === b.transforms.flip.vertical);
    }
}

export { isEqualStates };
