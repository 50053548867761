import { ratio, resizeToSizeRestrictions, sizeDistance } from './utils.js';
import { isLower, isGreater } from '../utils.js';

function validateSize(params) {
    var size = params.size, aspectRatio = params.aspectRatio, ignoreMinimum = params.ignoreMinimum, sizeRestrictions = params.sizeRestrictions;
    return (!isLower(ratio(size), aspectRatio.minimum) &&
        !isGreater(ratio(size), aspectRatio.maximum) &&
        !isGreater(size.height, sizeRestrictions.maxHeight) &&
        !isGreater(size.width, sizeRestrictions.maxWidth) &&
        size.width &&
        size.height &&
        (ignoreMinimum || (size.height >= sizeRestrictions.minHeight && size.width >= sizeRestrictions.minWidth)));
}
// Limitations:
// 1. Assume that maximum width and height always larger than minimum width and height
// 2. Assume that aspectRatio.minimum < aspectRatio.maximum
// If you break this limitations function could return null!
function approximateSize(params) {
    var width = params.width, height = params.height, sizeRestrictions = params.sizeRestrictions;
    var aspectRatio = {
        minimum: (params.aspectRatio && params.aspectRatio.minimum) || 0,
        maximum: (params.aspectRatio && params.aspectRatio.maximum) || Infinity,
    };
    var coordinates = {
        width: Math.max(sizeRestrictions.minWidth, Math.min(sizeRestrictions.maxWidth, width)),
        height: Math.max(sizeRestrictions.minHeight, Math.min(sizeRestrictions.maxHeight, height)),
    };
    function findBestCandidate(candidates, ignoreMinimum) {
        if (ignoreMinimum === void 0) { ignoreMinimum = false; }
        return candidates.reduce(function (minimum, size) {
            if (validateSize({ size: size, aspectRatio: aspectRatio, sizeRestrictions: sizeRestrictions, ignoreMinimum: ignoreMinimum })) {
                return !minimum || sizeDistance(size, { width: width, height: height }) < sizeDistance(minimum, { width: width, height: height })
                    ? size
                    : minimum;
            }
            else {
                return minimum;
            }
        }, null);
    }
    var candidates = [];
    if (aspectRatio) {
        [aspectRatio.minimum, aspectRatio.maximum].forEach(function (ratio) {
            if (ratio) {
                candidates.push({ width: coordinates.width, height: coordinates.width / ratio }, { width: coordinates.height * ratio, height: coordinates.height });
            }
        });
    }
    if (validateSize({ size: coordinates, aspectRatio: aspectRatio, sizeRestrictions: sizeRestrictions })) {
        candidates.push(coordinates);
    }
    // Resize the candidates as much as possible to prevent breaking minimum size
    candidates = candidates.map(function (candidate) { return resizeToSizeRestrictions(candidate, sizeRestrictions); });
    var candidate = findBestCandidate(candidates) || findBestCandidate(candidates, true);
    return (candidate && {
        width: candidate.width,
        height: candidate.height,
    });
}

export { approximateSize };
