var timingFunctions = {
    linear: function (t) {
        return t;
    },
    'ease-in': function (t) {
        return Math.pow(t, 1.675);
    },
    'ease-out': function (t) {
        return 1 - Math.pow(1 - t, 1.675);
    },
    'ease-in-out': function (t) {
        return 0.5 * (Math.sin((t - 0.5) * Math.PI) + 1);
    },
};
var Animation = /** @class */ (function () {
    function Animation(options) {
        this.timingFunction = options.timingFunction;
        this.duration = options.duration;
        this.onStart = options.onStart;
        this.onProgress = options.onProgress;
        this.onStop = options.onStop;
        this.active = false;
    }
    Animation.prototype.start = function () {
        this.startTime = performance.now();
        this.endTime = this.startTime + this.duration;
        if (this.onStart) {
            this.onStart();
        }
        this.active = true;
        this.animate();
    };
    Animation.prototype.animate = function () {
        var _this = this;
        var timingFunction = this.timingFunction in timingFunctions ? timingFunctions[this.timingFunction] : null;
        if (!timingFunction) {
            console.warn("[Animation] The timing function '" + timingFunction + "' is not supported. Available timing function: 'linear', 'ease-in', 'ease-in-out', 'ease-out'. Reset to 'ease-out'.");
            timingFunction = timingFunctions['ease-out'];
        }
        var percent = 1 - (this.endTime - performance.now()) / (this.endTime - this.startTime);
        var progress = Math.min(1, timingFunction(percent));
        if (this.onProgress) {
            this.onProgress(progress);
        }
        if (percent <= 1) {
            this.id = window.requestAnimationFrame(function () { return _this.animate(); });
        }
        else {
            this.stop();
        }
    };
    Animation.prototype.stop = function () {
        this.active = false;
        if (this.id) {
            window.cancelAnimationFrame(this.id);
        }
        if (this.onStop) {
            this.onStop();
        }
    };
    return Animation;
}());

export { Animation };
