import { rotateSize } from './utils.js';
import { isFunction, emptyCoordinates, isNumeric } from '../utils.js';
import { calculateAreaSizeRestrictions, calculateSizeRestrictions } from './sizeRestrictions.js';

function getAreaSizeRestrictions(state, settings) {
    return calculateAreaSizeRestrictions(state, settings);
}
function getAreaPositionRestrictions(state, settings) {
    return isFunction(settings.areaPositionRestrictions)
        ? settings.areaPositionRestrictions(state, settings)
        : settings.areaPositionRestrictions;
}
function getSizeRestrictions(state, settings) {
    return calculateSizeRestrictions(state, settings);
}
function getPositionRestrictions(state, settings) {
    return isFunction(settings.positionRestrictions)
        ? settings.positionRestrictions(state, settings)
        : settings.positionRestrictions;
}
function getCoefficient(state) {
    return state.visibleArea ? state.visibleArea.width / state.boundary.width : 0;
}
function getStencilCoordinates(state) {
    if (state) {
        var _a = state.coordinates, width = _a.width, height = _a.height, left = _a.left, top_1 = _a.top;
        var coefficient = getCoefficient(state);
        return {
            width: width / coefficient,
            height: height / coefficient,
            left: (left - state.visibleArea.left) / coefficient,
            top: (top_1 - state.visibleArea.top) / coefficient,
        };
    }
    else {
        return emptyCoordinates();
    }
}
function getAspectRatio(state, settings) {
    var result = isFunction(settings.aspectRatio) ? settings.aspectRatio(state, settings) : settings.aspectRatio;
    return {
        minimum: isNumeric(result.minimum) ? result.minimum : 0,
        maximum: isNumeric(result.maximum) ? result.maximum : Infinity,
    };
}
function getDefaultCoordinates(state, settings) {
    return isFunction(settings.defaultCoordinates)
        ? settings.defaultCoordinates(state, settings)
        : settings.defaultCoordinates;
}
function getDefaultVisibleArea(state, settings) {
    return isFunction(settings.defaultVisibleArea)
        ? settings.defaultVisibleArea(state, settings)
        : settings.defaultVisibleArea;
}
function getTransformedImageSize(state) {
    if (state.imageSize && state.imageSize.width && state.imageSize.height) {
        return rotateSize(state.imageSize, state.transforms.rotate);
    }
    else {
        return {
            width: 0,
            height: 0,
        };
    }
}
function getMinimumSize(state) {
    // The magic number is the approximation of the handler size
    // Temporary solution that should be improved in the future
    return Math.min(state.coordinates.width, state.coordinates.height, 20 * getCoefficient(state));
}

export { getAreaPositionRestrictions, getAreaSizeRestrictions, getAspectRatio, getCoefficient, getDefaultCoordinates, getDefaultVisibleArea, getMinimumSize, getPositionRestrictions, getSizeRestrictions, getStencilCoordinates, getTransformedImageSize };
