function getDirectionNames(hDirection, vDirection) {
    var camelCase, snakeCase;
    if (hDirection && vDirection) {
        camelCase = "" + hDirection + vDirection[0].toUpperCase() + vDirection.slice(1);
        snakeCase = hDirection + "-" + vDirection;
    }
    else {
        camelCase = hDirection || vDirection;
        snakeCase = hDirection || vDirection;
    }
    return { camelCase: camelCase, snakeCase: snakeCase };
}
function isBlob(url) {
    return /^blob:/.test(url);
}
function isDataUrl(url) {
    return /^data:/.test(url);
}
function isLocal(url) {
    return isBlob(url) || isDataUrl(url);
}
function isCrossOriginURL(url) {
    if (isLocal(url)) {
        return false;
    }
    var pageLocation = window.location;
    var URL_HOST_PATTERN = /(\w+:)?(?:\/\/)([\w.-]+)?(?::(\d+))?\/?/;
    var urlMatch = URL_HOST_PATTERN.exec(url) || [];
    var urlparts = {
        protocol: urlMatch[1] || '',
        host: urlMatch[2] || '',
        port: urlMatch[3] || '',
    };
    var defaultPort = function (protocol) {
        if (protocol === 'http') {
            return 80;
        }
        else {
            return 433;
        }
    };
    var portOf = function (location) {
        return location.port || defaultPort((location.protocol || pageLocation.protocol));
    };
    return !((!urlparts.protocol && !urlparts.host && !urlparts.port) ||
        Boolean(urlparts.protocol &&
            urlparts.protocol == pageLocation.protocol &&
            urlparts.host &&
            urlparts.host == pageLocation.host &&
            urlparts.host &&
            portOf(urlparts) == portOf(pageLocation)));
}
function isArray(value) {
    return Array.isArray(value);
}
function isFunction(value) {
    return typeof value === 'function';
}
function isUndefined(obj) {
    return obj === undefined;
}
function isObject(obj) {
    return typeof obj === 'object' && obj !== null;
}
function getOptions(options, defaultScheme, falseScheme) {
    if (falseScheme === void 0) { falseScheme = {}; }
    var result = {};
    if (isObject(options)) {
        Object.keys(defaultScheme).forEach(function (key) {
            if (isUndefined(options[key])) {
                result[key] = defaultScheme[key];
            }
            else if (isObject(defaultScheme[key])) {
                if (isObject(options[key])) {
                    result[key] = getOptions(options[key], defaultScheme[key], falseScheme[key]);
                }
                else {
                    result[key] = options[key] ? defaultScheme[key] : falseScheme[key];
                }
            }
            else if (defaultScheme[key] === true || defaultScheme[key] === false) {
                result[key] = Boolean(options[key]);
            }
            else {
                result[key] = options[key];
            }
        });
        return result;
    }
    else {
        if (options) {
            return defaultScheme;
        }
        else {
            return falseScheme;
        }
    }
}
function parseNumber(number) {
    var parsedNumber = Number(number);
    if (Number.isNaN(parsedNumber)) {
        return number;
    }
    else {
        return parsedNumber;
    }
}
function isEmpty(obj) {
    return (!obj || Object.keys(obj).length === 0) && typeof obj !== 'function';
}
function isObjectLike(value) {
    return typeof value === 'object' && value !== null;
}
function isNumeric(value) {
    return !Number.isNaN(parseFloat(value)) && isFinite(value);
}
function isNaN(value) {
    return value !== value;
}
function isNumber(value) {
    return typeof value === 'number';
}
function distance(firstPoint, secondPoint) {
    return Math.sqrt(Math.pow(firstPoint.x - secondPoint.x, 2) + Math.pow(firstPoint.y - secondPoint.y, 2));
}
function isRoughlyEqual(a, b, tolerance) {
    if (tolerance === void 0) { tolerance = 1e-3; }
    return Math.abs(b - a) < Math.max(tolerance, tolerance * Math.max(Math.abs(a), Math.abs(b)));
}
function isGreater(a, b, tolerance) {
    return isRoughlyEqual(a, b, tolerance) ? false : a > b;
}
function isLower(a, b, tolerance) {
    return isRoughlyEqual(a, b, tolerance) ? false : a < b;
}
function sign(value) {
    var number = +value;
    if (number === 0 || isNaN(number)) {
        return number;
    }
    return number > 0 ? 1 : -1;
}
function promiseTimeout(timeout) {
    return new Promise(function (resolve) {
        setTimeout(function () {
            resolve();
        }, timeout);
    });
}
function mapObject(obj, callback) {
    var result = {};
    Object.keys(obj).forEach(function (name) {
        result[name] = callback(obj[name], name);
    });
    return result;
}
function isWheelEvent(event) {
    return 'deltaX' in event;
}
function isTouchEvent(event) {
    return 'touches' in event;
}
function isMouseEvent(event) {
    return 'buttons' in event;
}
function emptyCoordinates() {
    return {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
    };
}

export { distance, emptyCoordinates, getDirectionNames, getOptions, isArray, isBlob, isCrossOriginURL, isDataUrl, isEmpty, isFunction, isGreater, isLocal, isLower, isMouseEvent, isNaN, isNumber, isNumeric, isObject, isObjectLike, isRoughlyEqual, isTouchEvent, isUndefined, isWheelEvent, mapObject, parseNumber, promiseTimeout, sign };
