import { __assign } from 'tslib';
import { ALL_DIRECTIONS } from '../constants.js';

function toLimits(object) {
    return {
        left: object.left,
        top: object.top,
        right: object.left + object.width,
        bottom: object.top + object.height,
    };
}
function diff(firstObject, secondObject) {
    return {
        left: firstObject.left - secondObject.left,
        top: firstObject.top - secondObject.top,
    };
}
function sizeDistance(a, b) {
    return Math.pow(a.width - b.width, 2) + Math.pow(a.height - b.height, 2);
}
function getCenter(object) {
    return {
        left: object.left + object.width / 2,
        top: object.top + object.height / 2,
    };
}
function getIntersections(object, limits) {
    var intersections = {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    };
    ALL_DIRECTIONS.forEach(function (direction) {
        var areaLimit = limits[direction];
        var objectLimit = toLimits(object)[direction];
        if (areaLimit !== undefined && objectLimit !== undefined) {
            if (direction === 'left' || direction === 'top') {
                intersections[direction] = Math.max(0, areaLimit - objectLimit);
            }
            else {
                intersections[direction] = Math.max(0, objectLimit - areaLimit);
            }
        }
        else {
            intersections[direction] = 0;
        }
    });
    return intersections;
}
function applyDirections(coordinates, directions) {
    return {
        left: coordinates.left - directions.left,
        top: coordinates.top - directions.top,
        width: coordinates.width + directions.left + directions.right,
        height: coordinates.height + directions.top + directions.bottom,
    };
}
function inverseMove(directions) {
    return {
        left: -directions.left,
        top: -directions.top,
    };
}
function applyMove(object, move) {
    return __assign(__assign({}, object), { left: object.left + move.left, top: object.top + move.top });
}
function applyScale(object, factor, center, progress) {
    if (factor !== 1) {
        if ('left' in object || 'top' in object) {
            if (center) {
                var currentCenter = getCenter(object);
                return {
                    width: object.width * factor,
                    height: object.height * factor,
                    left: object.left +
                        (object.width * (1 - factor)) / 2 +
                        (center.left - currentCenter.left) * (progress || 1 - factor),
                    top: object.top +
                        (object.height * (1 - factor)) / 2 +
                        (center.top - currentCenter.top) * (progress || 1 - factor),
                };
            }
            else {
                return {
                    width: object.width * factor,
                    height: object.height * factor,
                    left: object.left + (object.width * (1 - factor)) / 2,
                    top: object.top + (object.height * (1 - factor)) / 2,
                };
            }
        }
        else {
            return {
                width: object.width * factor,
                height: object.height * factor,
            };
        }
    }
    else {
        return object;
    }
}
function ratio(object) {
    return object.width / object.height;
}
function maxScale(size, restrictions) {
    return Math.min(restrictions.maxWidth ? restrictions.maxWidth / size.width : Infinity, restrictions.maxHeight ? restrictions.maxHeight / size.height : Infinity);
}
function minScale(size, restrictions) {
    return Math.max(restrictions.minWidth ? restrictions.minWidth / size.width : 0, restrictions.minHeight ? restrictions.minHeight / size.height : 0);
}
function getBrokenRatio(currentAspectRatio, aspectRatio) {
    var ratioBroken;
    if (aspectRatio.minimum && currentAspectRatio < aspectRatio.minimum) {
        ratioBroken = aspectRatio.minimum;
    }
    else if (aspectRatio.maximum && currentAspectRatio > aspectRatio.maximum) {
        ratioBroken = aspectRatio.maximum;
    }
    return ratioBroken;
}
function fitToSizeRestrictions(coordinates, sizeRestrictions) {
    var aspectRatio = ratio(coordinates);
    var scale = 1;
    if (sizeRestrictions.minWidth > 0 && sizeRestrictions.minHeight > 0) {
        if (aspectRatio > sizeRestrictions.minWidth / sizeRestrictions.minHeight) {
            if (coordinates.height < sizeRestrictions.minHeight) {
                scale = sizeRestrictions.minHeight / coordinates.height;
            }
        }
        else {
            if (coordinates.width < sizeRestrictions.minWidth) {
                scale = sizeRestrictions.minWidth / coordinates.width;
            }
        }
    }
    else if (sizeRestrictions.minWidth > 0) {
        if (coordinates.width < sizeRestrictions.minWidth) {
            scale = sizeRestrictions.minWidth / coordinates.width;
        }
    }
    else if (sizeRestrictions.minHeight > 0) {
        if (coordinates.height < sizeRestrictions.minHeight) {
            scale = sizeRestrictions.minHeight / coordinates.height;
        }
    }
    if (sizeRestrictions.maxWidth < Infinity && sizeRestrictions.maxHeight < Infinity) {
        if (aspectRatio > sizeRestrictions.maxWidth / sizeRestrictions.maxHeight) {
            if (coordinates.width > sizeRestrictions.maxWidth) {
                scale = sizeRestrictions.maxWidth / coordinates.width;
            }
        }
        else {
            if (coordinates.height > sizeRestrictions.maxHeight) {
                scale = sizeRestrictions.maxHeight / coordinates.height;
            }
        }
    }
    else if (sizeRestrictions.maxWidth < Infinity) {
        if (coordinates.width > sizeRestrictions.maxWidth) {
            scale = sizeRestrictions.maxWidth / coordinates.width;
        }
    }
    else if (sizeRestrictions.maxHeight < Infinity) {
        if (coordinates.height > sizeRestrictions.maxHeight) {
            scale = sizeRestrictions.maxHeight / coordinates.height;
        }
    }
    return scale;
}
function resizeToSizeRestrictions(coordinates, sizeRestrictions) {
    return applyScale(coordinates, fitToSizeRestrictions(coordinates, sizeRestrictions));
}
function rotateSize(size, angle) {
    var radians = (angle * Math.PI) / 180;
    return {
        width: Math.abs(size.width * Math.cos(radians)) + Math.abs(size.height * Math.sin(radians)),
        height: Math.abs(size.width * Math.sin(radians)) + Math.abs(size.height * Math.cos(radians)),
    };
}
function rotatePoint(point, angle, anchor) {
    var radians = (angle * Math.PI) / 180;
    if (anchor) {
        return {
            left: (point.left - anchor.left) * Math.cos(radians) -
                (point.top - anchor.top) * Math.sin(radians) +
                anchor.left,
            top: (point.left - anchor.left) * Math.sin(radians) +
                (point.top - anchor.top) * Math.cos(radians) +
                anchor.top,
        };
    }
    else {
        return {
            left: point.left * Math.cos(radians) - point.top * Math.sin(radians),
            top: point.left * Math.sin(radians) + point.top * Math.cos(radians),
        };
    }
}
function positionToSizeRestrictions(positionRestrictions) {
    return {
        minWidth: 0,
        minHeight: 0,
        maxWidth: positionRestrictions.right !== undefined && positionRestrictions.left !== undefined
            ? positionRestrictions.right - positionRestrictions.left
            : Infinity,
        maxHeight: positionRestrictions.bottom !== undefined && positionRestrictions.top !== undefined
            ? positionRestrictions.bottom - positionRestrictions.top
            : Infinity,
    };
}
function mergePositionRestrictions(a, b) {
    var limits = {};
    ALL_DIRECTIONS.forEach(function (direction) {
        var firstDirection = a[direction];
        var secondDirection = b[direction];
        if (firstDirection !== undefined && secondDirection !== undefined) {
            if (direction === 'left' || direction === 'top') {
                limits[direction] = Math.max(firstDirection, secondDirection);
            }
            else {
                limits[direction] = Math.min(firstDirection, secondDirection);
            }
        }
        else if (secondDirection !== undefined) {
            limits[direction] = secondDirection;
        }
        else if (firstDirection !== undefined) {
            limits[direction] = firstDirection;
        }
    });
    return limits;
}
function fitToPositionRestrictions(coordinates, positionRestrictions) {
    var directions = {
        left: 0,
        top: 0,
    };
    var intersection = getIntersections(coordinates, positionRestrictions);
    if (intersection.left && intersection.left > 0) {
        directions.left = intersection.left;
    }
    else if (intersection.right && intersection.right > 0) {
        directions.left = -intersection.right;
    }
    if (intersection.top && intersection.top > 0) {
        directions.top = intersection.top;
    }
    else if (intersection.bottom && intersection.bottom > 0) {
        directions.top = -intersection.bottom;
    }
    return directions;
}
function moveToPositionRestrictions(coordinates, positionRestrictions) {
    return applyMove(coordinates, fitToPositionRestrictions(coordinates, positionRestrictions));
}
function coordinatesToPositionRestrictions(coordinates) {
    return {
        left: coordinates.left,
        top: coordinates.top,
        right: coordinates.left + coordinates.width,
        bottom: coordinates.top + coordinates.height,
    };
}

export { applyDirections, applyMove, applyScale, coordinatesToPositionRestrictions, diff, fitToPositionRestrictions, fitToSizeRestrictions, getBrokenRatio, getCenter, getIntersections, inverseMove, maxScale, mergePositionRestrictions, minScale, moveToPositionRestrictions, positionToSizeRestrictions, ratio, resizeToSizeRestrictions, rotatePoint, rotateSize, sizeDistance, toLimits };
